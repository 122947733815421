<div class="container" fxLayout="column" fxLayoutGap="16px">
    <div class="header">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <span class="mat-title">メンバー</span>
            <span fxLayout="row" fxFlexOffset="auto">
                <button mat-icon-button (click)="onRefresh()"><mat-icon>refresh</mat-icon></button>
            </span>
        </div>
    </div>
    <div>
        <div flexLayout="row" fxLayoutAlign="space-between" fxLayoutGap="20px">
            <div class="full-width">
            <table mat-table [dataSource]="dataSourceIni" class="mat-elevation-z8">

                <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->
            
                <!-- id Column -->
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef>ID</th>
                    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                </ng-container>

                <!-- name Column -->
                <ng-container matColumnDef="content">
                    <th mat-header-cell *matHeaderCellDef>内容</th>
                    <td mat-cell *matCellDef="let element"> {{element.content}} </td>
                </ng-container>
            
                <tr mat-header-row *matHeaderRowDef="displayedColumnsIni"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsIni;"></tr>
            </table>
            </div>

            <div class="full-width">
            <table mat-table [dataSource]="dataSourceAtt" class="mat-elevation-z8">

                <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->
            
                <!-- id Column -->
                <ng-container matColumnDef="id">
                    <th class="green-header" mat-header-cell *matHeaderCellDef>ID</th>
                    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                </ng-container>

                <!-- name Column -->
                <ng-container matColumnDef="name">
                    <th class="green-header" mat-header-cell *matHeaderCellDef>在室者</th>
                    <td mat-cell *matCellDef="let element"> {{element.content}} </td>
                </ng-container>
            
                <tr mat-header-row *matHeaderRowDef="displayedColumnsAtt"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsAtt;"></tr>
            </table>
            </div>

            <div class="full-width">
            <table mat-table [dataSource]="dataSourceAbs" class="mat-elevation-z8">

                <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->
            
                <!-- id Column -->
                <ng-container matColumnDef="id">
                    <th class="blue-header" mat-header-cell *matHeaderCellDef>ID</th>
                    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                </ng-container>

                <!-- name Column -->
                <ng-container matColumnDef="name">
                    <th class="blue-header" mat-header-cell *matHeaderCellDef>外室者</th>
                    <td mat-cell *matCellDef="let element"> {{element.content}} </td>
                </ng-container>
            
                <tr mat-header-row *matHeaderRowDef="displayedColumnsAbs"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsAbs;"></tr>
            </table>
            </div>
        </div>
    </div>
</div>
  


