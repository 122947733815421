<mat-card>
    <mat-card-header>
        <mat-card-title class="mat-title">アカウント</mat-card-title>
    </mat-card-header>
    <mat-divider inset="true"></mat-divider>
    <mat-card-content>
        <div fxLayout="row wrap" fxLayoutGap="24px">

            <div fxFlex="36" class="full-width">
                <div fxLayout="column" fxLayoutAlign="space-between none">
                    <form class="form" [formGroup]="form" novalidate (ngSubmit)="onGenAccount()">
                        <mat-label>アカウント発行</mat-label>
                        <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="16px">
                            <mat-form-field class="example-full-width">
                                <input matInput type="email" placeholder="登録するメールアドレス" formControlName="email">
                                <mat-error *ngIf="emailControl.hasError('required') || emailControl.hasError('email')">
                                    <strong>メールアドレスを入力してください</strong>
                                </mat-error>
                            </mat-form-field>

                            <mat-checkbox [(ngModel)]="user.admin" formControlName="admin">管理者</mat-checkbox>
                            <button mat-raised-button color="primary" type="submit" [disabled]="form.invalid">発行</button>
                        </div>

                        <span class="password" *ngIf="success">
                            パスワード: <span>{{user.password}}</span>
                            <button mat-icon-button type="button" (click)="onCopy()">
                                <mat-icon>file_copy</mat-icon>
                            </button>
                        </span>
                    </form>

                    <form class="form" [formGroup]="subForm" (ngSubmit)="onSubscribe()">
                        <mat-label>異常時メール通知先登録</mat-label>
                        <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="16px">
                            <mat-form-field class="example-full-width">
                                <input matInput type="email" placeholder="通知先メールアドレス" formControlName="subEmail">
                                <mat-error *ngIf="subEmailControl.hasError('required') || subEmailControl.hasError('email')">
                                    <strong>メールアドレスを入力してください</strong>
                                </mat-error>
                            </mat-form-field>
                            <button mat-raised-button color="primary" type="submit" [disabled]="subForm.invalid">登録</button>
                        </div>
                    </form>
                </div>
            </div>

            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" fxFlex>

                <!-- email Column -->
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef>メールアドレス</th>
                    <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                </ng-container>

                <ng-container matColumnDef="admin">
                    <th mat-header-cell *matHeaderCellDef>管理者</th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="element.admin"><mat-icon color="accent">star</mat-icon></span>
                    </td>
                </ng-container>

                <!-- action Column -->
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef>アクション</th>
                    <td mat-cell *matCellDef="let element">
                        <!--<button mat-icon-button color="primary" (click)="onEdit(element.email)">
                            <mat-icon>edit</mat-icon>
                        </button>-->
                        <button *ngIf="!(element.email === email)" mat-icon-button color="warn" (click)="onDelete(element.email)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </mat-card-content>
</mat-card>

<mat-card>
    <mat-card-header>
        <mat-card-title class="mat-title">休日設定</mat-card-title>
    </mat-card-header>
    <mat-divider inset="true"></mat-divider>
    <mat-card-content>
        <div fxLayout="column" fxLayoutGap="8px">
            <div>
                <span fxLayout="row" fxFlexAlign=" center" fxLayoutGap="14px">
                    <mat-form-field>
                        <mat-label>日付</mat-label>
                        <input matInput [matDatepicker]="pickerHoli" [(ngModel)]="selectedData.date" placeholder="YYYY/MM/DD">
                        <mat-datepicker-toggle matSuffix [for]="pickerHoli"></mat-datepicker-toggle>
                        <mat-datepicker #pickerHoli color="primary"></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>休日名</mat-label>
                        <input matInput type="text" [(ngModel)]="selectedData.remark">
                    </mat-form-field>
                    <!--button mat-mini-fab color="primary" (click)="onAdd()"><mat-icon>add</mat-icon></button-->
                    <button mat-icon-button class="action-btn" color="primary" (click)="onAdd()"><mat-icon>add</mat-icon></button>
                </span>
            </div>
            <mat-chip-list>
                <mat-chip *ngFor="let viewHoliday of viewHolidays" (removed)="onRemove(viewHoliday.date)">
                    {{viewHoliday.view}}
                    <button matChipRemove>
                        <mat-icon>cancel</mat-icon>
                    </button>
                </mat-chip>
            </mat-chip-list>
        </div>
    </mat-card-content>
</mat-card>

<mat-card>
    <mat-card-header>
        <mat-card-title class="mat-title">メッセージ</mat-card-title>
        <mat-card-subtitle>以下に入力した内容がそのままダッシュボードに表示されます</mat-card-subtitle>
    </mat-card-header>
    <mat-divider inset="true"></mat-divider>
    <mat-card-content>
        <span fxLayout="row" fxFlexAlign=" center" fxLayoutGap="14px">
            <mat-form-field class="message">
                <mat-label>内容</mat-label>
                <input matInput type="text" [(ngModel)]="inputMessage" placeholder="YYYY/MM/DD これはメッセージです">
            </mat-form-field>
            <!--button mat-mini-fab color="primary" (click)="onMessageAdd()"><mat-icon>send</mat-icon></button-->
            <button mat-icon-button class="action-btn" color="primary" (click)="onMessageAdd()"><mat-icon>send</mat-icon></button>
        </span>
    </mat-card-content>
</mat-card>

<mat-card class="danger-zone">
    <mat-card-header>
        <mat-card-title class="mat-title" color="warn">Danger Zone</mat-card-title>
    </mat-card-header>
    <mat-divider inset="true"></mat-divider>
    <mat-card-content>
        <div fxLayout="column" fxLayoutGap="16px">
            <div>
                <span fxLayout="row wrap" fxLayoutAlign=" center" fxLayoutGap="16px">
                    <!--<button mat-raised-button matTooltip="全メンバーが状態管理なし状態になります" matTooltipPosition="above" color="warn" type="button" (click)="onZaru()">状態管理なしモード</button>-->
                    <button mat-raised-button color="warn" type="button" (click)="onZaru()">状態管理なしモード</button>
                    <p class="info">全てのメンバーが状態管理なし状態になります。状態管理なし状態ではアンチパスバックにはなりません</p>
                </span>
            </div>
            <div>
                <span fxLayout="row wrap" fxLayoutAlign=" center" fxLayoutGap="16px">
                    <mat-form-field color="warn">
                        <mat-label><mat-icon class="filter-icon">filter_list</mat-icon>範囲選択</mat-label>
                        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                            <input matStartDate formControlName="start" placeholder="YYYY/MM/DD">
                            <input matEndDate formControlName="end" placeholder="YYYY/MM/DD">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                        <mat-error *ngIf="range.controls['start'].hasError('matStartDateInvalid') || range.controls['end'].hasError('matEndDateInvalid')">
                            不正な範囲です
                        </mat-error>
                    </mat-form-field>
                    <button mat-raised-button color="warn" (click)="onDeleteLog()" [disabled]="range.controls['start'].hasError('matStartDateInvalid') || range.controls['end'].hasError('matEndDateInvalid')">ログ削除</button>
                    <p class="info">選択された範囲のログを削除します。選択がない場合は全てのログが削除されます</p>
                </span>
            </div>
            <div>
                <span fxLayout="row wrap" fxLayoutAlign=" center" fxLayoutGap="16px">
                    <mat-checkbox [(ngModel)]="currentUser.showExecutive" [ngModelOptions]="{standalone: true}" (change)="onCheckShowExecutive($event)">役員の勤務時間を表示</mat-checkbox>
                </span>
            </div>

        </div>
    </mat-card-content>
</mat-card>
