<span fxLayout="row" fxLayoutAlign="space-between center">
    <h2 mat-dialog-title>手動打刻 ({{data.name}}さん)</h2>
    <span class="info" matTooltip="手動打刻ではメンバーの状態管理には関与せず、ログのみを残します。間違えたとしても、最終的に正しい打刻になっていれば問題はありません。">
        <mat-icon>info</mat-icon>
    </span>
</span>
<div mat-dialog-content>
    <div fxLayout="column" fxLayoutGap="24px">
        <div>
            <!--<p>{{data.name}}さん</p>-->
            <p>以下より該当する打刻を選択してください</p>
            <mat-form-field class="full-width">
                <!--mat-label>デバイス</mat-label-->
                <mat-select [(ngModel)]="deviceId">
                    <mat-option *ngFor="let device of deviceOptions" [value]="device.id">
                        {{device.view}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div>
            <span fxLayout="row" fxLayoutAlign="center center" class="clock">
                {{clock}}
            </span>
        </div>
        <div class="spacer">
            <span fxLayout="row" fxLayoutAlign="center center">
                <button mat-fab type="button" color="warn" (click)="onStamp()">
                    <mat-icon>pets</mat-icon>
                </button>
            </span>
        </div>
    </div>
</div>
<div mat-dialog-actions align="end">
    <button mat-button (click)="onCancel()">キャンセル</button>
</div>