import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DbService } from '../../services/db.service';
import { member } from '../../models/member';
import { SpinnerService } from '../../services/spinner.service';
import screenfull from 'screenfull';
import { holiday } from '../../models/holiday';
import { user } from '../../models/user';

export interface selectMonth {
  view: string,
  date: Date
}

export interface displayData {
  [key: string]: string
}

export interface dynamicColumn {
  view: string,
  def: string
}

export interface kind {
  name: string,
  status: number
}

export interface calcSet {
  sumMonth: displayData,
  s_number: number[],
  workDate: displayData,
  w_number: number[],
  overtimeHours: displayData
  o_number: number[]
}

function ymdEqual(d1:Date, d2:Date):boolean {
    return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
}

@Component({
  selector: 'app-work-hours-chart',
  templateUrl: './work-hours-chart.component.html',
  styleUrls: ['./work-hours-chart.component.scss']
})
export class WorkHoursChartComponent implements OnInit {
  selector: selectMonth[] = [];
  selected: Date = new Date();
  dataSource = new MatTableDataSource<displayData>();
  displayedColumns: string[] = [];
  dates: selectMonth[] = [];
  dynamicColumns: dynamicColumn[] = [];
  holidays: holiday[] = [];
  user: user = {
    email: '',
    password: '',
    admin: false,
    associated_member_id: 0,
    showExecutive: false
  }

  constructor(
    private dbService: DbService,
    private spinnerService: SpinnerService
  ) { }

  ngOnInit(): void {
    this.getSelector();
    this.getUser(); /* admin および showExecutive で役員の勤務時間表示/非表示を判定する */
    this.getTableData(); /* 勤務時間表の計算処理本体: 初期化時に計算が行われる */
    this.getHolidays();
  }

  selectedChange(): void {
    this.init();
    this.getTableData();
  }

  init(): void {
    this.dates = [];
    this.dataSource.data = [];
    this.displayedColumns = [];
    this.dynamicColumns = [];
  }

  getSelector(): void {
    /* For 5 years */
    const maxYears = 5;
    let now = new Date();
    let year = now.getFullYear();
    let month = now.getMonth() + 1;
    for(let i = 0; i < maxYears * 12; i++){
      this.selector.push({
        view: `${year}年${month}月`,
        date: new Date(year, month - 1)
      });
      month = month - 1;
      if(!(month > 0)){
        year = year - 1;
        month = 12;
      }
    }
    this.selected = this.selector[0].date;
  }

  getDate(): void {
    /* TODO: 本筋の修正後これも分けて直して速度を見る */
    // let dateArr = ['日', '月', '火', '水', '木', '金', '土',];
    // let dateNum = new Date(this.selected.getFullYear(), this.selected.getMonth() + 1, 0).getDate();
    // /* new Date(YYYY, N+1, 0) は (N+1)月0日 すなわち N月最終日の Date Object となるため,
    //    dateNum には selected の月の最終日すなわちその月の日数が束縛される */
    for(let i = 0; i < new Date(this.selected.getFullYear(), this.selected.getMonth() + 1, 0).getDate(); i++){
      let date = new Date(this.selected.getFullYear(), this.selected.getMonth(), i + 1);
      let day: string = ''; // = dateArr[date.getDay()];
      switch(date.getDay()){
        case 0:
          day = '日';
          break;
        case 1:
          day = '月';
          break;
        case 2:
          day = '火';
          break;
        case 3:
          day = '水';
          break;
        case 4:
          day = '木';
          break;
        case 5:
          day = '金';
          break;
        case 6:
          day = '土';
          break;
        default:
          break;
      }
      this.dates.push({
        view: `${date.getDate()} (${day})`,
        date: date
      });
    }
  }



  getTableData(): void {
    this.spinnerService.attach();

    this.getDate();

    this.dbService.getAll<member>('members')
      .subscribe(members => {
        let showIds: number[] = [];
        if(!(this.user.admin) || !(this.user.showExecutive)) {
            let nonExecMem = members.filter(member => !(member.isExecutive));
            showIds = nonExecMem.map(member => member.id);
        }

        this.dbService.getWorkHours(showIds, this.dates[0].date.toString(), this.dates[this.dates.length - 1].date.toString(), 30)
        .subscribe(workHourData => {

        /* Make column */
        let kinds: kind[] = [];

        for(let workListWithName of workHourData) {
          let member = members.find(member => member.id === workListWithName.id);
          if(member === undefined) {
            continue;
          }

          kinds.push({
            name: workListWithName.name,
            status: member.status
          });
        }

        this.dynamicColumns.push({
          view: '日付',
          def: 'date'
        });
        for(let i = 0; i < kinds.length; i++) {
          this.dynamicColumns.push({
            view: kinds[i].name,
            def: String(i)
          });
        }
        this.displayedColumns = this.dynamicColumns.map(el => el.def); /* ['date', '0', '1', '2', ...] */
        this.dynamicColumns.shift(); /* [ {view:'名前0', def:'0'}, {view:'名前1', def:'1'}, {view:'名前2', def:'2'}, ...] */

        /* Make data */
        let displayWorkHours: displayData[] = [];
        let calcSet: calcSet = {
          sumMonth: {},
          s_number: new Array<number>(kinds.length),
          workDate: {},
          w_number: new Array<number>(kinds.length),
          overtimeHours: {},
          o_number: new Array<number>(kinds.length)
        }
        /* 'date' キーで表示の日付列に表示する文字列を取り出すように */
        calcSet.sumMonth['date'] = '月総計';
        calcSet.workDate['date'] = '出勤日数';
        calcSet.overtimeHours['date'] = '残業目安';
        calcSet.s_number.fill(0);
        calcSet.w_number.fill(0);
        calcSet.o_number.fill(0);

        /*
          getWorkHours のレスポンスは以下の形:
          [
            {id:1, name:name1, workList: [work11, work12, ...]},
            {id:2, name:name2, workList: [work21, work22, ...]},
            ...
          ]
          ここで workNM は以下の形:
          {
            date: date.toString(),
            start: resultObj.start,
            end: resultObj.end,
            hours: resultObj.hours,
            manualStart: manual.start,
            manualEnd: manual.end
          }
        */

        /* 先に日毎のデータ hoursByDate を用意する */
        /*  [
                {date:date1, nameWorkNist:[{id:11, name:name11, work:work11},
                                            {id:12, name:name12, work:work12},
                                            ...]
                },
                {date:date2, nameWorkList:[{id:21, name:name21, work:work21},
                                            {id:22, name:name22, work:work22},
                                            ...]
                },
                ...
            ]
        */
        let hoursByDate = [];
        for(let date of this.dates) {
            let nameWorkList = [];
            for(let workHour of workHourData) {
                let work = workHour.workList.find(el => ymdEqual(date.date, new Date(el.date)));
                if(work) {
                    nameWorkList.push({id:workHour.id, name:workHour.name, work:work});
                }
            }
            hoursByDate.push({date:date, nameWorkList:nameWorkList})
        }

        /* 日毎に表示するデータを生成・ */
        const now = new Date();
        for(let date of this.dates) {
            let tableRow: displayData = {}; /* 表示する横一列分 (= 1日分) のデータ */
            tableRow['date'] = date.view;

            /* 年月日が一致するデータのみを取り出す */
            let nameWorkList = hoursByDate.find(el => el.date === date)?.nameWorkList;
            if(!nameWorkList || nameWorkList.length === 0) { /* その日の勤務時間の記録がなければスキップ */
                displayWorkHours.push(tableRow); /* 日付以外が空のデータを push */
                continue;
            }
            for(let i = 0; i < kinds.length; i++) { /* 勤務時間の記録があるので各メンバーについて調べる */
                let text: string = '';
                let nameWork = nameWorkList.find(el => el.name === kinds[i].name);
                if(!nameWork) { /* i 番目のメンバーはこの日勤務していなかったのでスキップ */
                    continue;
                }
                let work = nameWork.work;
                if(work.start && work.end){
                    if(work.manualStart) {
                        work.start += '*';
                    }
                    if(work.manualEnd) {
                        work.end += '*';
                    }
                    const workDate = new Date(work.date);

                    if(kinds[i].status === 1 && ymdEqual(now, workDate)) {
                      text = `出勤<br>(${work.start}～)`;
                    }
                    else {
                      calcSet.w_number[i] += 1;
                      let hours: string;
                      if(work.hours.slice(-2) == '00') {
                        hours = `${work.hours.slice(0, 2)}`;
                      }
                      else {
                        hours = `${work.hours.slice(0, 2)}.5`;
                      }

                      //calcSet.s_number[i] += Number(hours) - 1;
                      let num_hours: number = Number(hours);
                      if(num_hours > 6) {
                        num_hours = num_hours - 1;
                      }
                      if(num_hours === 0) {
                        text = `退勤<br>(${work.start})<br>【実務】${num_hours}時間`;
                        calcSet.w_number[i] += -1;
                      }
                      else {
                        text = `退勤<br>(${work.start}～${work.end})<br>【実務】${num_hours}時間`;
                        const day = this.getDay(date.view);
                        if(day === 1 || day === 2) {
                          calcSet.o_number[i] += num_hours;
                        }
                        else {
                          calcSet.o_number[i] += num_hours - 8;
                        }
                      }
                      calcSet.s_number[i] += num_hours;
                    }
                }/* END if(work.start && work.end) { */
                else if(work.start && !work.end) {
                    if(work.manualStart) work.start += '*';
                    text = `出勤<br>(${work.start}～)`;
                }
                tableRow[`${i}`] = text;
            } /* END for(let i = 0; i < kinds.length; i++) { */

          displayWorkHours.push(tableRow); /* 1日分のデータを push */
        } /* END for(let date of this.dates){ */

        /* Calc information */
        for(let i = 0; i < kinds.length; i++) {
          calcSet.sumMonth[`${i}`] = `${calcSet.s_number[i]}時間`;
          calcSet.workDate[`${i}`] = `${calcSet.w_number[i]}日`;
          calcSet.overtimeHours[`${i}`] = `${calcSet.o_number[i]}時間`;
        }
        displayWorkHours.push(calcSet.sumMonth);
        displayWorkHours.push(calcSet.workDate);
        displayWorkHours.push(calcSet.overtimeHours);
        this.dataSource.data = displayWorkHours;

        this.spinnerService.detach();
      });
    });
  }

  toggleFullscreen(element: HTMLElement): void {
    if(screenfull.isEnabled) {
      screenfull.toggle(element);
    }
  }

  getDay(date: string): number {
    let result = 0;
    let day = date.slice(-2, -1);
    switch(day) {
      case '土':
        result = 1;
        break;
      case '日':
        if(date==='出勤日数') {
          break;
        }
        result = 2;
        break;
      default:
        break;
    }
    let dateNumber = Number(date.slice(0, -3));
    if(dateNumber !== NaN) {
      const genDate = new Date(`${this.selected.getFullYear()}/${this.selected.getMonth() + 1}/${dateNumber}`).getTime();
      if(this.holidays.map(el => el.date).includes(String(genDate))) {
        result = 2;
      }
    }
    return result;
  }

  getHolidays(): void {
    this.dbService.getAll<holiday>('holidays')
    .subscribe(holidays => this.holidays = holidays);
  }

  getUser(): void {
    this.dbService.getUser()
    .subscribe(user => this.user = user);
  }
}
