<h2 mat-dialog-title>メンバーリンク</h2>
<div *ngIf="!user.associated_member_id || user.associated_member_id === 0; else elseBlock">
    <div mat-dialog-content>
    <p>{{user.email}} にメンバーを紐づけます</p>
    <mat-form-field class="full-width">
        <mat-select [(value)]="selectedId">
            <mat-option (value)="0"></mat-option>
            <mat-option *ngFor="let option of options" [value]="option.id">{{option.view}}</mat-option>
        </mat-select>
    </mat-form-field>
    </div>
    <div mat-dialog-actions class="dialog-buttons">
    <button mat-button (click)="onCancel()">キャンセル</button>
    <span fxFlexOffset="auto">
        <button mat-raised-button color="primary" (click)="onSetMember()">紐づける</button>
    </span>
    </div>
</div>
<ng-template #elseBlock>
    <div mat-dialog-content>
        <p>現在、{{user.email}} に{{name}}さんが紐づいています</p>
    </div>
    <div mat-dialog-actions class="dialog-buttons">
    <button mat-button (click)="onCancel()">キャンセル</button>
    <span fxFlexOffset="auto">
        <button mat-raised-button color="warn" (click)="onReleaseMember()">解除する</button>
    </span>
    </div>
</ng-template>
