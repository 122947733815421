<mat-tab-group>
    <mat-tab label="情報">
        <div class="container" fxLayout="column" fxLayoutGap="16px">
            <div class="header">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <span class="mat-title">メンバー</span>
                    <span fxLayout="row" fxFlexOffset="auto" fxLayoutAlign="center center">
                        <button mat-icon-button (click)="onRefresh()"><mat-icon>refresh</mat-icon></button>
                        <button mat-button (click)="onRegister()">登録</button>
                    </span>
                </div>
            </div>
            <div>
                <table mat-table [dataSource]="dataSource" matSort matSortActive="id" matSortDirection="asc" class="mat-elevation-z8">

                    <!--- Note that these columns can be defined in any order.
                        The actual rendered columns are set as a property on the row definition" -->

                    <!-- id Column -->
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
                        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                    </ng-container>

                    <!-- name Column -->
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>名前</th>
                        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>

                    <!-- lastname Column -->
                    <ng-container matColumnDef="lastname">
                        <th mat-header-cell *matHeaderCellDef>セイ</th>
                        <td mat-cell *matCellDef="let element"> {{element.lastname}} </td>
                    </ng-container>

                    <!-- firstname Column -->
                    <ng-container matColumnDef="firstname">
                        <th mat-header-cell *matHeaderCellDef>メイ</th>
                        <td mat-cell *matCellDef="let element"> {{element.firstname}} </td>
                    </ng-container>

                    <!-- company Column -->
                    <ng-container matColumnDef="company">
                        <th mat-header-cell *matHeaderCellDef>所属</th>
                        <td mat-cell *matCellDef="let element"> {{element.company}} </td>
                    </ng-container>

                    <!-- enable Column -->
                    <ng-container matColumnDef="enable">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                        <span class="accent-color" *ngIf="element.enable=='有効'">{{element.enable}}</span>
                        <span class="warn-color" *ngIf="element.enable=='無効'">{{element.enable}}</span>
                        </td>
                    </ng-container>

                    <!-- status Column -->
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef>状況</th>
                        <td mat-cell *matCellDef="let element">
                            <div [ngSwitch]="element.status">
                                <span class="primary-color" *ngSwitchCase="'外室'">{{element.status}}</span>
                                <span class="accent-color" *ngSwitchCase="'在室'">{{element.status}}</span>
                                <span *ngSwitchCase="'初期状態'">{{element.status}}</span>
                                <span class="warn-color" *ngSwitchCase="'アンチパスバック'">
                                    {{element.status}}<button mat-icon-button (click)="onReset(element.id)" matTooltip="初期状態になります" matTooltipPosition="right"><mat-icon class="init-icon">new_releases</mat-icon></button>
                                </span>
                                <span *ngSwitchCase="'状態管理なし'">
                                    {{element.status}}
                                </span>
                            </div>
                        </td>
                    </ng-container>

                    <!-- edit Column -->
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <button mat-icon-button type="button" [matMenuTriggerFor]="memberMenu">
                                <mat-icon class="more_vert">more_vert</mat-icon>
                            </button>
                            <mat-menu #memberMenu="matMenu" yPosition="below" xPosition="before">
                                <button mat-menu-item (click)="onReset(element.id)">
                                    <mat-icon>new_releases</mat-icon>
                                    初期化
                                </button>
                                <button mat-menu-item (click)="onEdit(element.id)" *ngIf="element.display">
                                    <mat-icon>edit</mat-icon>
                                    編集
                                </button>
                                <button mat-menu-item color="warn" (click)="onDelete(element.id, element.name)" *ngIf="element.display">
                                    <mat-icon>delete</mat-icon>
                                    削除
                                </button>
                                <button mat-menu-item class="timer" color="primary" *ngIf="!(element.status === 'アンチパスバック') && element.id === user.associated_member_id" (click)="onStamp(element.id, element.name)">
                                    <mat-icon>timer</mat-icon>
                                    手動打刻
                                </button>
                            </mat-menu>
                        </td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </div>
    </mat-tab>
    <mat-tab label="状況">
        <ng-template matTabContent>
            <app-status-list></app-status-list>
        </ng-template>
    </mat-tab>
</mat-tab-group>
