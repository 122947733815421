<div class="container" fxLayout="column" fxLayoutGap="16px">

    <div class="header">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <span class="mat-title">デバイス</span>
            <span fxLayout="row" fxFlexOffset="auto" fxLayoutAlign="center center">
                <button mat-icon-button (click)="onRefresh()"><mat-icon>refresh</mat-icon></button>
                <button mat-button (click)="onRegister()">登録</button>
            </span>
        </div>
    </div>

    <div>
        <table mat-table [dataSource]="dataSource" matSort matSortActive="id" matSortDirection="asc" class="mat-elevation-z8">

            <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->
        
            <!-- id Column -->
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>

            <!-- name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>デバイス名</th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <!-- role Column -->
            <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef>役割</th>
                <td mat-cell *matCellDef="let element"> {{element.role}} </td>
            </ng-container>

            <!-- partner Column -->
            <ng-container matColumnDef="partner">
                <th mat-header-cell *matHeaderCellDef>パートナー</th>
                <td mat-cell *matCellDef="let element"> {{element.partner}} </td>
            </ng-container>

            <!-- status Column -->
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>状況</th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="element.status === '通常'; else elseBlock">
                        <span class="accent-color">{{element.status}}</span>
                    </div> 
                    <ng-template #elseBlock>{{element.status}}({{counters[element.id]}})</ng-template>
                </td>
            </ng-container>

            <!-- tmpopen Column -->
            <ng-container matColumnDef="tmpopen" class="tmpopen-btn">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="element.status === '通常' && element.role === '出口'">
                        <button mat-flat-button type="button" color="primary" (click)="onTempOpen(element.id, element.name, element.timeout)">一時解錠</button>
                    </div> 
                    <div *ngIf="element.status === '一時解錠中' && element.role === '出口'">
                        <button mat-flat-button color="warn" type="button" (click)="onLock(element.id, element.name)">施錠</button>
                    </div>
                </td>
            </ng-container>

            <!-- timeout Column -->
            <ng-container matColumnDef="timeout">
                <th mat-header-cell *matHeaderCellDef>最大解錠時間</th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="element.status === '通常' && element.role === '出口'">
                        {{element.timeout}}
                    </div> 
                </td>
            </ng-container>


            <!-- action Column -->
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef>アクション</th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button color="primary" (click)="onEdit(element.id)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button color="warn" (click)="onDelete(element.id, element.name)" [disabled]="!isAdmin">
                        <mat-icon>delete</mat-icon>
                    </button> 
                </td>
            </ng-container>

        
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>

</div>
  

