import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';

import { SNSClient, SubscribeCommand} from '@aws-sdk/client-sns';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers'

import { user } from '../../models/user';
import { holiday } from '../../models/holiday';
import { message } from '../../models/message';
import { AuthService } from '../../services/auth.service';
import { DbService } from '../../services/db.service';
import { AccountDeleteComponent } from '../dialogs/account-delete/account-delete.component';
import { AccountLogDeleteComponent } from '../dialogs/account-log-delete/account-log-delete.component';

const snsClient = new SNSClient({
  region: 'ap-northeast-1',
  credentials: fromCognitoIdentityPool({
    clientConfig: {region: 'ap-northeast-1'},
    identityPoolId: 'ap-northeast-1:a7bda7eb-405d-4973-8c23-e3bae3c5c15a'
  })
});

export interface viewHoliday {
  view: string,
  date: string,
  remark: string
}

export interface displayData {
  email: string,
  admin: boolean
}

export interface selectedData {
  date: string,
  remark: string
}

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  user: user = { /**アカウント生成用 user オブジェクト */
    email: '',
    password: '',
    admin: false,
    showExecutive: false
  }
  currentUser: user = { /** 現在ログイン中のユーザアカウント情報 */
    email: '',
    password: '',
    admin: false,
    showExecutive: false
  }
  dummy: boolean = false; /* モックアップ用ダミーアトリビュート */
  email: string = '';
  form!: FormGroup;
  emailControl = new FormControl(null, [
    Validators.required,
    Validators.email
  ]);

  subForm!: FormGroup;
  subEmailControl = new FormControl(null, [
    Validators.required,
    Validators.email
  ]);

  adminControl = new FormControl(null);
  success: boolean = false;
  checked: boolean = false;
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  selectedData: selectedData = {date: '', remark: ''};
  viewHolidays: viewHoliday[] = [];

  displayedColumns: string[] = [
    'email',
    'admin',
    'action'
  ];
  dataSource = new MatTableDataSource<displayData>();
  inputMessage: string = '';

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private dbService: DbService,
    private snackBar: MatSnackBar,
    private clipboard: Clipboard,
    private router: Router,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.viewHolidays = [];
    this.selectedData = {date: '', remark: ''};
    this.getUser();
    this.getUsers();
    this.getHolidays();
    this.form = this.fb.group({
      email: this.emailControl,
      admin: this.adminControl
    });
    this.subForm = new FormGroup({
      subEmail: this.subEmailControl
    });
  }

  onResetPW(): void {
    this.dbService.resetPW()
    .subscribe(token => {
      this.authService.logout()
      .subscribe(() => {
        this.router.navigate([`/reset/${token}`]);
      });
    });
  }

  onGenAccount(): void {
    this.user.email = this.form.get('email')?.value;
    this.authService.genPW()
    .subscribe(password => {
      if(password){
        this.user.password = password;
        this.dbService.exist('user', this.user.email)
        .subscribe(exist => {
          if(exist){
            this.success = false;
            this.snackBar.open('このアドレスは既に登録済みです', '閉じる', { duration: 7000 });
          }
          else{
            this.dbService.createUser(this.user)
            .subscribe(result => {
              if(!result){
                this.success = false;
                this.snackBar.open('アカウントを発行できませんでした', '閉じる', { duration: 7000 });
              }
              else{
                this.success = true;
                this.snackBar.open('アカウントを発行しました', '閉じる', { duration: 5000 });
                this.ngOnInit();
              }
            });
          }
        });
      }
    });
  }

  onCopy(): void {
    this.clipboard.copy(this.user.password);
    this.snackBar.open('コピーしました', '閉じる', { duration: 4000 });
  }

  getUsers(): void {
    this.dbService.getAll<user>('users')
    .subscribe(users => {
      let displayUsers: displayData[] = [];
      users.forEach(user => {
        displayUsers.push({
          email: user.email,
          admin: user.admin
        });
      });
      this.dataSource.data = displayUsers;
    });
  }

  getUser(): void {
    this.dbService.getUser()
    // .subscribe(user => this.email = user.email);
    .subscribe(user => {
        // this.user = user;
        this.currentUser.email = user.email;
        this.currentUser.showExecutive = user.showExecutive;
    });
  }

  onDelete(email: string): void {
		let dialogRef = this.dialog.open(AccountDeleteComponent, {
		  width: '400px',
		  data: email
		});
		dialogRef.afterClosed().subscribe(() => {
		  this.ngOnInit();
		});
	}

  onZaru(): void {
    this.dbService.modeChange('zaru')
    .subscribe(result => {
      if(result){
        this.snackBar.open('状態管理なしモードにしました', '閉じる', { duration: 5000 });
      }
      else{
        this.snackBar.open('モード変更できませんでした', '閉じる', { duration: 7000 });
      }
    });
  }

  onDeleteLog(): void {
    if(!((this.range.value['start'] && this.range.value['end']) || (!this.range.value['start'] && !this.range.value['end']))){

      this.snackBar.open('正しい範囲を入力してください', '閉じる', {duration: 7000});
      return;
    }
    let dialogRef = this.dialog.open(AccountLogDeleteComponent, {
      width: '480px',
      data: {
        start: this.range.value['start'],
        end: this.range.value['end']
      }
    });
    dialogRef.afterClosed().subscribe(() => {
        this.ngOnInit();
    });
  }

  getHolidays(): void {
    this.dbService.getAll<holiday>('holidays')
    .subscribe(holidays => {
      holidays.forEach(holiday => {
        let date = new Date(Number(holiday.date));
        this.viewHolidays.push({
          view: `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${holiday.remark}`,
          date: holiday.date,
          remark: holiday.remark
        });
      });
      this.viewHolidays.sort((x, y) => Number(x.date) - Number(y.date));
    });
  }

  onRemove(date: string){
    this.dbService.delete('holiday', date)
    .subscribe(result => {
      if(result){
        this.ngOnInit();
        //this.viewHolidays = this.viewHolidays.filter(el => el.date !== date);
      }
      else{
        this.snackBar.open('削除できませんでした', '閉じる', {duration: 7000});
        //this.ngOnInit();
      }
    });
  }

  onAdd(): void {
    if(!this.selectedData.date){
      this.snackBar.open('日付を入力してください', '閉じる', {duration: 7000});
      return;
    }
    if(this.isDup()){
      this.snackBar.open('その日付は既に追加されています', '閉じる', {duration: 7000});
    }
    else{
      let body: holiday = {
        date: String(new Date(this.selectedData.date).getTime()),
        remark: this.selectedData.remark
      }
      this.dbService.add<holiday>('holiday', body)
      .subscribe(result => {
        if(result){
          this.snackBar.open('追加しました', '閉じる', {duration: 5000});
          this.ngOnInit();
        }
        else{
          this.snackBar.open('追加できませんでした', '閉じる', {duration: 7000});
        }
      });
    }
  }

  onMessageAdd(): void {
    if(!this.inputMessage){
      this.snackBar.open('内容を入力してください', '閉じる', {duration: 7000});
      return;
    }
    let message: message = {
      timestamp: Date.now(),
      content: this.inputMessage
    }
    this.dbService.add<message>('message', message)
    .subscribe(result => {
      if(result){
        this.snackBar.open('メッセージを作成しました', '閉じる', {duration: 5000});
        this.inputMessage = '';
      }
      else{
        this.snackBar.open('メッセージを作成できませんでした', '閉じる', {duration: 7000});
      }
    })
  }

  isDup(): boolean {
    const selected = new Date(this.selectedData.date);
    for(let d of this.viewHolidays){
      const date = new Date(Number(d.date));
      if(date.getFullYear() === selected.getFullYear() && date.getMonth() === selected.getMonth() && date.getDate() === selected.getDate()){
        return true;
      }
    }
    return false;
  }

  onCheckShowExecutive(event:MatCheckboxChange): void {
    console.log('Checkbox change event triggered');
    console.log(event.checked);
    this.currentUser.showExecutive = event.checked;
    this.dbService.update<user>('user/showExecutive', this.currentUser).subscribe();
  }

  onSubscribe(): void {
    try {
      let showString: string = '確認メールを送信しました';
      console.log('onSubscribe() triggered, with value:');
      console.log(this.subForm.get('subEmail')?.value);
      snsClient.send(
        new SubscribeCommand({
          TopicArn: "arn:aws:sns:ap-northeast-1:333385460580:testTopic",
          Protocol: 'EMAIL',
          Endpoint: this.subForm.get('subEmail')?.value
        }),
      )
      .then(
        function(data) {
          console.log('snsClient.send succeeded, data:')
          console.log(data);

        }
      )
      .catch(
        function(err) {
          showString = 'エラー: メールアドレスを確認してください'
          console.log('snsClient.send failed, error:')
          console.log(err);
        }
      );
      this.snackBar.open(showString, '閉じる', {duration: 5000});
      this.ngOnInit();
    } catch (error) {
      console.log('error caught on onSubscribe()');
      console.log(error);
    }
  }
}
