<h2 mat-dialog-title>デバイス登録</h2>
<div mat-dialog-content>
  <form class="example-form" [formGroup]="form" novalidate (ngSubmit)="onSubmit()">
    <div class="spacer" fxLayout="column" fxLayoutAlign="space-around">

      <mat-form-field class="example-full-width">
        <mat-label>ID</mat-label>
        <mat-select [formControl]="idControl">
          <mat-option>--</mat-option>
          <mat-option *ngFor="let id of idOptions" [value]="id">
            {{id}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="idControl.hasError('required')">
            <strong>IDを選択してください</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input matInput placeholder="名前" name="name" formControlName="name">
        <mat-error *ngIf="nameControl.hasError('required')">
            <strong>名前を入力してください</strong>
        </mat-error>
      </mat-form-field>

      <mat-label>役割</mat-label>
      <mat-radio-group formControlName="func" (change)="setPartners()">
          <mat-radio-button *ngFor="let func of funcList" [value]="func.value">{{func.view}}</mat-radio-button>
          <mat-error *ngIf="funcControl.hasError('required')">
              <span>役割を選択してください</span>
          </mat-error>
      </mat-radio-group>

      <div class="virtual-toggle">
        <mat-slide-toggle formControlName="enable">バーチャル</mat-slide-toggle>
      </div>

      <mat-form-field class="example-full-width">
        <mat-label>パートナー</mat-label>
        <mat-select [formControl]="partnerControl" [disabled]="virtual">
          <mat-option>--</mat-option>
          <mat-option *ngFor="let partner of partnerOptions" [value]="partner.value">
            {{partner.view}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>
    <span  class="dialog-btn" fxLayout="row" fxLayoutAlign="space-between center">
      <button mat-button type="button" (click)="onCancel()">キャンセル</button>
      <button mat-raised-button class="btn-block" color="primary" type="submit" [disabled]="form.invalid">登録</button>
    </span>
  </form>


</div>
