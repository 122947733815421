<h2 mat-dialog-title>カード登録</h2>
<div mat-dialog-content>
    <div *ngIf="!mobile" fxLayout="column" fxLayoutAlign="space-around center" class="nfc">
        <div *ngIf="scanStatus==0"><mat-icon class="nfc-icon" color="primary">nfc</mat-icon></div>
        <div *ngIf="scanStatus==1"><mat-spinner diameter="60" color="primary"></mat-spinner></div>
        <div *ngIf="scanStatus==2"><mat-icon class="nfc-icon" color="primary">done</mat-icon></div>
    
        <button mat-raised-button color="primary" type="button" (click)="onScan()" [disabled]="scanStatus==1">スキャン</button>
    </div>
    <form class="example-form" [formGroup]="form" novalidate (ngSubmit)="onSubmit()">
        <div class="spacer" fxLayout="column" fxLayoutAlign="space-around">

            <mat-form-field class="example-full-width">
                <input matInput placeholder="IDm" name="idm" [(ngModel)]="card.idm" formControlName="idm">
                <mat-error *ngIf="idmControl.hasError('required')">
                    <strong>カードをスキャンしてください</strong>
                </mat-error>
            </mat-form-field>

            <mat-form-field class="example-full-width">
            <mat-label>紐づけ先</mat-label>
            <mat-select [formControl]="idControl">
                <mat-option>--</mat-option>
                <mat-option *ngFor="let member of members" [value]="member.id">
                {{member.name}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="idControl.hasError('required')">
                <strong>紐づけ先を選択してください</strong>
            </mat-error>
            </mat-form-field>

            <!--<mat-form-field class="example-full-width">-->
               <mat-slide-toggle formControlName="enable">有効</mat-slide-toggle>
            <!--</mat-form-field>-->

            <mat-form-field class="example-full-width">
                <mat-label>備考</mat-label>
                <textarea matInput name="remark" formControlName="remark"></textarea>
            </mat-form-field>

            <div [@.disabled]="disableAnimation">
                <mat-accordion>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                許可しないデバイス
                                <!--<mat-icon>filter_list</mat-icon>-->
                            </mat-panel-title>
                            <!--<mat-panel-description>
                            </mat-panel-description>-->
                        </mat-expansion-panel-header>
                        <span fxLayout="row wrap" fxLayoutGap="10px">
                            <span *ngFor="let viewDev of viewDevs">
                                <mat-checkbox class="example-margin" formControlName="banDevids" [(ngModel)]="viewDev.checked">{{viewDev.name}}</mat-checkbox>
                            </span>
                        </span>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>

        </div>
        <span  class="dialog-btn" fxLayout="row" fxLayoutAlign="space-between center">
            <button mat-button type="button" (click)="onCancel()">キャンセル</button>
            <button mat-raised-button class="btn-block" color="primary" type="submit" [disabled]="form.invalid">カード登録</button>
        </span>
    </form>


</div>