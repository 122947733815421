<mat-tab-group>
    <mat-tab label="一覧">
        <div class="container" fxLayout="column">
            <div class="header">
                <span fxLayout="row" fxLayoutAlign="space-between center">
                    <span class="mat-title">勤務時間</span>
                    <span class="month-select" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
                        <button mat-icon-button (click)="toggleFullscreen(table)">
                            <mat-icon>fullscreen</mat-icon>
                        </button>
                        <mat-form-field>
                            <mat-select [(ngModel)]="selected" (selectionChange)="selectedChange()">
                                <mat-option [value]="option.date" *ngFor="let option of selector">{{option.view}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </span>
                </span>
            </div>

            <div class="table-container mat-elevation-z8" #table>
                <table mat-table [dataSource]="dataSource">

                    <!--- Note that these columns can be defined in any order.
                        The actual rendered columns are set as a property on the row definition" -->

                    <!-- date Column -->
                    <!-- 日付を表示する縦列 -->
                    <ng-container matColumnDef="date" sticky>
                        <!-- ヘッダは "日付" -->
                        <th class="w-header" mat-header-cell *matHeaderCellDef>日付</th>
                        <!-- 要素は "1(月)" のような各日付の文字列 -->
                        <!-- element は getTableData() で生成される tableRow 達であり 'date' をキーに文字列が取り出される -->
                        <td class="w-data date-data" mat-cell *matCellDef="let element"
                            [class.saturday]="getDay(element['date'])===1" [class.sunday]="getDay(element['date'])===2">
                            {{element['date']}} </td>
                    </ng-container>

                    <!-- workhour Column -->
                    <!-- 各メンバーの勤務時間を表示する縦列を ngFor* でそれぞれ生成 -->
                    <!-- column.def はメンバーリスト生成時のインデックス -->
                    <ng-container *ngFor="let column of dynamicColumns" [matColumnDef]="column.def">
                        <!-- ヘッダは {{column.view}} として参照したメンバーの名前-->
                        <th class="w-header" mat-header-cell *matHeaderCellDef>{{column.view}}</th>
                        <!-- 要素は getTableData() で生成した勤務時間のHTML文字列であり, column.def すなわち生成時のインデックスをキーとして参照する-->
                        <td class="w-data workHours-data" mat-cell *matCellDef="let element"
                            [innerHTML]="element[column.def]" [class.saturday]="getDay(element['date'])===1"
                            [class.sunday]="getDay(element['date'])===2"></td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
            </div>
        </div>
    </mat-tab>
    <mat-tab label="出力">
        <ng-template matTabContent>
            <app-work-hours></app-work-hours>
        </ng-template>
    </mat-tab>
</mat-tab-group>
