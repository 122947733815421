import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-matprogressspinner',
  templateUrl: './matprogressspinner.component.html',
  styleUrls: ['./matprogressspinner.component.scss']
})
export class MatprogressspinnerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
