<h2 mat-dialog-title>カード編集</h2>
<div mat-dialog-content>
    <form class="example-form" [formGroup]="form" novalidate (ngSubmit)="onSave()">
        <div class="spacer" fxLayout="column" fxLayoutAlign="space-around">

            <mat-form-field class="example-full-width">
                <input matInput placeholder="IDm" name="idm" [(ngModel)]="card.idm" formControlName="idm" readonly>
                <mat-hint>IDmは編集できません</mat-hint>
            </mat-form-field>

            <!--<mat-form-field class="example-full-width">
                <input matInput placeholder="紐づけ先" name="id" [(ngModel)]="member.name" formControlName="id" readonly>
                <mat-hint>紐づけ先は編集できません</mat-hint>
            </mat-form-field>-->
            <mat-form-field class="edit-form">
                <mat-label>紐づけ先</mat-label>
                <mat-select [formControl]="idControl" [(ngModel)]="card.id">
                    <mat-option>--</mat-option>
                    <mat-option *ngFor="let member of members" [value]="member.id">
                        {{member.name}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="idControl.hasError('required')">
                    <strong>紐づけ先を選択してください</strong>
                </mat-error>
            </mat-form-field>
    

            <!--<mat-form-field class="example-full-width">-->
               <mat-slide-toggle formControlName="enable">有効</mat-slide-toggle>
            <!--</mat-form-field>-->

            <mat-form-field class="example-full-width">
                <mat-label>備考</mat-label>
                <textarea matInput name="remark" [(ngModel)]="card.remark" formControlName="remark"></textarea>
            </mat-form-field>

            <div [@.disabled]="disableAnimation">
                <mat-accordion>
                    <mat-expansion-panel [expanded]="false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                許可しないデバイス
                                <!--<mat-icon>filter_list</mat-icon>-->
                            </mat-panel-title>
                            <!--<mat-panel-description>
                            </mat-panel-description>-->
                        </mat-expansion-panel-header>
                        
                        <div formArrayName="banDevids">
                            <span fxLayout="row wrap" fxLayoutGap="10px">
                                <div *ngFor="let banDevid of banDevids.controls; let i = index">
                                    <div [formGroupName]="i">
                                        <!--<span *ngFor="let viewDev of viewDevs">-->
                                            <mat-checkbox class="example-margin" formControlName="banDevidCtl" [(ngModel)]="viewDevs[i].checked">{{viewDevs[i].name}}</mat-checkbox>
                                        <!--</span>-->
                                    </div>
                                </div>
                            </span>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>

        </div>
        <span  class="dialog-btn" fxLayout="row" fxLayoutAlign="space-between center">
            <button mat-button type="button" (click)="onCancel()">キャンセル</button>
            <button mat-raised-button class="btn-block" color="primary" type="submit" [disabled]="form.invalid">更新</button>
        </span>
    </form>


</div>