
<div class="container" fxLayout="column" fxLayoutGap="8px" cdkScrollable>
        <div class="header">
            <div fxLayout="row warp" fxLayoutAlign="space-between center">
                <span class="mat-title" [class.mobileTitle]="mobileTitle">ログ</span>
                <span class="sei-mei" fxLayout="row" fxLayoutAlign="center center">
                    <mat-form-field>
                        
                        <mat-label>選択された範囲</mat-label>
                        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                            <input matStartDate formControlName="start" [disabled]="true">
                            <input matEndDate formControlName="end" [disabled]="true">
                        </mat-date-range-input>
                        
                        <mat-date-range-picker #picker disabled="false">
                            <mat-datepicker-actions>
                                <button mat-button matDatepickerCancel>キャンセル</button>
                                <button mat-raised-button color="primary" (click)="onFilter()" matDatepickerApply>フィルター</button>
                            </mat-datepicker-actions>
                        </mat-date-range-picker>
                        <!--
                        <mat-error *ngIf="range.controls['start'].hasError('matStartDateInvalid')">不正な開始時刻です</mat-error>
                        <mat-error *ngIf="range.controls['end'].hasError('matEndDateInvalid')">不正な終了時刻です</mat-error>
                        -->
                    </mat-form-field>
                    <button mat-icon-button (click)="picker.open()"><mat-icon>filter_list</mat-icon></button>
                    <button mat-icon-button (click)="onRefresh()"><mat-icon>refresh</mat-icon></button>
                    <button mat-button (click)="exporter.exportTable('csv', options)" matTooltip="表示されているデータのみが出力されます">CSV出力</button>
                </span>
            </div>
        </div>

        <div class="mat-elevation-z8">
            <table mat-table matTableExporter [dataSource]="dataSource" #exporter="matTableExporter" (exportStarted)="onCSVStart()" (exportCompleted)="onCSVFinish()">

                <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->
            
                <!-- no Column -->
                <ng-container matColumnDef="no">
                    <th mat-header-cell *matHeaderCellDef>No.</th>
                    <td mat-cell *matCellDef="let element"> {{element.no}} </td>
                </ng-container>

                <!-- date Column -->
                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef>日付</th>
                    <td mat-cell *matCellDef="let element"> {{element.date}} </td>
                </ng-container>

                <!-- time Column -->
                <ng-container matColumnDef="time">
                    <th mat-header-cell *matHeaderCellDef>時刻</th>
                    <td mat-cell *matCellDef="let element"> {{element.time}} </td>
                </ng-container>

                <!-- idm Column -->
                <ng-container matColumnDef="idm">
                    <th mat-header-cell *matHeaderCellDef>IDm</th>
                    <td mat-cell *matCellDef="let element"> {{element.idm}} </td>
                </ng-container>

                <!-- id Column -->
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef>ID</th>
                    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                </ng-container>

                <!-- name Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>名前</th>
                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>

                <!-- devName Column -->
                <ng-container matColumnDef="devName">
                    <th mat-header-cell *matHeaderCellDef>タッチ端末</th>
                    <td mat-cell *matCellDef="let element"> {{element.devName}} </td>
                </ng-container>

                <!-- prevStat Column -->
                <ng-container matColumnDef="prevStat">
                    <th mat-header-cell *matHeaderCellDef>タッチ前状態</th>
                    <td mat-cell *matCellDef="let element"> {{element.prevStat}} </td>
                </ng-container>

                <!-- success Column -->
                <ng-container matColumnDef="result">
                    <th mat-header-cell *matHeaderCellDef>状況</th>
                    <td mat-cell *matCellDef="let element"> {{element.result}} </td>
                </ng-container>




                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <!--mat-paginator [pageSizeOptions]="[50, 200, 1000]"></mat-paginator-->
        </div>
</div>
   

