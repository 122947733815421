<h2 mat-dialog-title>QRコード発行</h2>
<div mat-dialog-content>
    <form class="example-form" [formGroup]="form" novalidate (ngSubmit)="onSubmit()">
        <mat-form-field class="example-full-width">
            <input matInput placeholder="メールアドレス" name="mailto" formControlName="mailto">
            <mat-error *ngIf="mailtoControl.hasError('required')">
                <strong>名前を入力してください</strong>
            </mat-error>
        </mat-form-field>

        <mat-checkbox [checked]="showExpire" formControlName="cb">有効期限を表示する</mat-checkbox>

        <span  class="dialog-btn" fxLayout="row" fxLayoutAlign="space-between center">
            <button mat-button type="button" (click)="onCancel()">キャンセル</button>
            <button mat-raised-button class="btn-block" color="primary" type="submit" [disabled]="form.invalid">送信</button>
        </span>

    </form>
</div>
