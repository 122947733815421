
<h2 mat-dialog-title>デバイス編集</h2>
<div mat-dialog-content>
  <form class="example-form" [formGroup]="form" novalidate (ngSubmit)="onSave()">
    <div class="spacer" fxLayout="column" fxLayoutAlign="space-around">
      <mat-form-field class="example-full-width">
          <input matInput placeholder="ID" name="id" type="number" [(ngModel)]="device.id" formControlName="id" readonly>
          <mat-hint>IDは編集できません</mat-hint>
      </mat-form-field>

      <mat-form-field class="edit-form">
        <input matInput placeholder="名前" name="name" [(ngModel)]="device.name" formControlName="name">
        <mat-error *ngIf="nameControl.hasError('required')">
            <strong>名前を入力してください</strong>
        </mat-error>
      </mat-form-field>

      <mat-label>役割</mat-label>
      <mat-radio-group formControlName="func" [disabled]="!isAdmin" (change)="setPartners()">
          <mat-radio-button *ngFor="let func of funcList" [value]="func.value">{{func.view}}</mat-radio-button>
          <mat-error *ngIf="funcControl.hasError('required')">
              <strong>役割を選択してください</strong>
          </mat-error>
      </mat-radio-group>

      <div class="virtual-toggle">
        <mat-slide-toggle formControlName="enable">バーチャル</mat-slide-toggle>
      </div>

      <mat-form-field class="example-full-width">
        <mat-label>パートナー</mat-label>
        <mat-select [formControl]="partnerControl" [disabled]="virtual">
          <mat-option>--</mat-option>
          <mat-option *ngFor="let partner of partnerOptions" [value]="partner.value">
            {{partner.view}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div *ngIf="funcControl.value !== 'enter'">
        <mat-form-field class="edit-form">
          <mat-label>最大解錠時間</mat-label>
          <mat-select [formControl]="timeoutControl" [disabled]="virtual">
            <mat-option>--</mat-option>
            <mat-option *ngFor="let timeout of timeoutOptions" [value]="timeout.value">
              {{timeout.view}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="timeoutControl.hasError('required')">
            <strong>設定してください</strong>
          </mat-error>
        </mat-form-field>
      </div>
      
    </div>
    <span  class="dialog-btn" fxLayout="row" fxLayoutAlign="space-between center">
      <button mat-button type="button" (click)="onCancel()">キャンセル</button>
      <button mat-raised-button class="btn-block" color="primary" type="submit" [disabled]="form.invalid">更新</button>
    </span>
  </form>


</div>
