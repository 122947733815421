import { Component, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DbService } from '../../services/db.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { filter } from '../../models/filter';
import { MatExpansionPanel } from '@angular/material/expansion';
import { member } from '../../models/member';
import { SpinnerService } from '../../services/spinner.service';
import { user } from '../../models/user';
import { Observable } from 'rxjs';

export interface displayData {
  id: number,
  name: string,
  date: string,
  start: string,
  end: string,
  hours: string
}

export interface checkbox {
  id: number,
  name: string,
  checked: boolean
}

export interface options {
  fileName: string
}

export interface roundOption {
  viewValue: string,
  value: number
}

@Component({
  selector: 'app-work-hours',
  templateUrl: './work-hours.component.html',
  styleUrls: ['./work-hours.component.scss']
})
export class WorkHoursComponent implements OnInit, AfterViewInit{
  yesterday: number = 0;
  filter: filter = {
    ids: [],
    start: 0,
    end: 0,
    roundMin: 0
  }
  displayedColumns: string[] = [
    'id',
    'name',
    'date',
    'start',
    'end',
    'hours'
  ];
  dataSource = new MatTableDataSource<displayData>();
  options: options = {
    fileName: ''
  }
  checkboxList: checkbox[] = [];
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  roundOptions: roundOption[] = [
    {viewValue: 'なし', value: 0},
    {viewValue: '15分', value: 15},
    {viewValue: '30分', value: 30}
  ]
  selectedRound: number = 0;
  user: user = {
    email: '',
    password: '',
    admin: false,
    associated_member_id: 0,
    showExecutive: false
  }

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('panel') panel!: MatExpansionPanel;

  constructor(
    private dbService: DbService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private spinnerService: SpinnerService
  ) { }

  ngOnInit(): void {
    this.yesterday = new Date().setDate(new Date().getDate() - 1);
    this.filter = {
      ids: [],
      start: new Date().setMonth(new Date(this.yesterday).getMonth()),
      end: this.yesterday,
      roundMin: 0
    }
    this.options.fileName = 'ddldoor_workHours';
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.getUser().subscribe(user => {
        this.user = user;
        this.getMembers().subscribe(members => {
            members.forEach(member => {
                if( !(member.isExecutive) // 役員でないなら必ず表示
                    || (this.user.admin && this.user.showExecutive) ) // 役員の場合は admin かつ showExecutive なアカウントなら表示
                {
                    this.checkboxList.push({
                        id: member.id,
                        name:member.name,
                        checked: false
                    });
                }
            });
            this.getWorkHours();
        }) /**END membersObs.subscribe */
    }); /**END userObs.subscribe */
  }

  getWorkHours(): void {
    if(this.checkboxList.length === 0) {
        console.error('checkboxList not initialized!');
        return;
    }
    this.spinnerService.attach();
    if(!((this.range.value['start'] && this.range.value['end']) || (!this.range.value['start'] && !this.range.value['end']))){
      this.snackBar.open('正しい範囲を入力してください', '閉じる', {duration: 7000});
      this.spinnerService.detach();
      return;
    }
    let checkedList = this.checkboxList.filter(el => el.checked === true);
    let ids: number[] = [];
    checkedList.forEach(el => {
      ids.push(el.id);
    });
    // チェックボックスなしのとき, admin かつ showExecutive でないなら明示的に役員以外の全メンバーのIDを渡す
    if(ids.length === 0 && !(this.user.admin && this.user.showExecutive) ) {
        ids = this.checkboxList.map(el => el.id);
    }
    this.dbService.getWorkHours(ids, String(this.range.value['start']), String(this.range.value['end']), this.selectedRound)
    .subscribe(workHoursArr => {
      let displaylogs: displayData[] = [];
      workHoursArr.forEach(workListWithName => {
        let id = workListWithName.id;
        let name = workListWithName.name;
        workListWithName.workList.forEach(work => {
            let date = new Date(work.date);
            displaylogs.push({
                id: id,
                name: name,
                date:`${date.getFullYear()}/${this.pad(date.getMonth() + 1)}/${this.pad(date.getDate())}`,
                start: work.start,
                end: work.end,
                hours: work.hours
            });
        })
      });
      this.dataSource.data = displaylogs;
    //   this.panel.close();
      this.spinnerService.detach();
    });
  }

  pad(number: number): string {
    let str: string = `${('0' + String(number)).slice(-2)}`;
    return str;
  }

//   getMembers(): void {
//     console.log('getMembers() triggered')
//     this.dbService.getAll<member>('members')
//     .subscribe(members => {
//       members.forEach(member => {
//         if( !(member.isExecutive) // 役員でないなら必ず表示
//             || (this.user.admin && this.user.showExecutive) ) // 役員の場合は admin かつ showExecutive なアカウントなら表示
//         {
//             this.checkboxList.push({
//                 id: member.id,
//                 name:member.name,
//                 checked: false
//             });
//         }
//       });
//     });
//   }

  getMembers(): Observable<member[]> {
    return this.dbService.getAll<member>('members');
  }

  onRefresh(): void {
    this.ngOnInit();
  }

  getInitWorkHours(): void {
    //this.spinnerService.attach();
    let checkedList = this.checkboxList.filter(el => el.checked === true);
    let ids: number[] = [];
    checkedList.forEach(el => {
      ids.push(el.id);
    });
    // チェックボックスなしのとき, admin かつ showExecutive でないなら明示的に役員以外の全メンバーのIDを渡す
    if(ids.length === 0 && !(this.user.admin && this.user.showExecutive) ) {
        ids = this.checkboxList.map(el => el.id);
    }
    this.dbService.getWorkHours(ids, String(this.range.value['start']), String(this.range.value['end']), this.selectedRound)
    .subscribe(workHoursArr => {
      let displaylogs: displayData[] = [];
      workHoursArr.forEach(workListWithName => {
        let id = workListWithName.id;
        let name = workListWithName.name;
        workListWithName.workList.forEach(work => {
            let date = new Date(work.date);
            displaylogs.push({
            id: id,
            name: name,
            date:`${date.getFullYear()}/${this.pad(date.getMonth() + 1)}/${this.pad(date.getDate())}`,
            start: work.start,
            end: work.end,
            hours: work.hours
            });
        })
      });
      this.dataSource.data = displaylogs;
      this.spinnerService.detach();
    });
  }

  onCSVStart(): void {
    this.spinnerService.attach();
  }

  onCSVFinish(): void {
    this.spinnerService.detach();
  }

//   getUser(): void {
//     console.log('getUser() triggered')
//     this.dbService.getUser()
//     .subscribe(user => {
//         this.user = user
//     });
//   }

  getUser(): Observable<user> {
    return this.dbService.getUser();
  }

}
