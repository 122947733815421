<div class="container" fxLayout="column" fxLayoutGap="16px">
  <div class="header">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <span class="mat-title">カードID</span>
      <span fxLayout="row" fxFlexOffset="auto" fxLayoutAlign="center center">
          <button mat-icon-button (click)="onRefresh()"><mat-icon>refresh</mat-icon></button>
          <button mat-button (click)="onCardRegister()">登録</button>
      </span>
    </div>
  </div>
  <div>
      <table mat-table [dataSource]="dataSource" matSort matSortActive="id" matSortDirection="asc" class="mat-elevation-z8">

          <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->
      
          <!-- id Column -->
          <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
              <td mat-cell *matCellDef="let element"> {{element.id}} </td>
          </ng-container>

          <!-- name Column -->
          <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>名前</th>
              <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>

          <!-- idm Column -->
          <ng-container matColumnDef="idm">
              <th mat-header-cell *matHeaderCellDef>IDm</th>
              <td mat-cell *matCellDef="let element"> {{element.idm}} </td>
          </ng-container>

          <!-- enable Column -->
          <ng-container matColumnDef="enable">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <span class="accent-color" *ngIf="element.enable=='有効'">{{element.enable}}</span>
                <span class="warn-color" *ngIf="element.enable=='無効'">{{element.enable}}</span>
              </td>
          </ng-container>

          <!-- expire Column -->
          <ng-container matColumnDef="expire">
              <th mat-header-cell *matHeaderCellDef>有効期限</th>
              <td mat-cell *matCellDef="let element"> {{element.expire}} </td>
          </ng-container>

          <!-- remark Column -->
          <ng-container matColumnDef="remark">
              <th mat-header-cell *matHeaderCellDef>備考</th>
              <td mat-cell *matCellDef="let element"> {{element.remark}}</td>
          </ng-container>

          <!-- remark Column -->
          <ng-container matColumnDef="banDevids">
            <th mat-header-cell *matHeaderCellDef>無許可デバイスID</th>
            <td mat-cell *matCellDef="let element"> {{element.banDevids}}</td>
          </ng-container>

          <!-- edit Column -->
          <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef>アクション</th>
              <td mat-cell *matCellDef="let element">
                  <button mat-icon-button color="primary" (click)="onEdit(element.idm)">
                      <mat-icon>edit</mat-icon>
                  </button>
                  <button mat-icon-button color="warn" (click)="onDelete(element.idm)">
                    <mat-icon>delete</mat-icon>
                  </button> 
              </td>
          </ng-container>

      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
</div>














<!--

<div class="mainArea">
    <div class="button1">
      <span class="col-1" style="display: inline-block; width: 800px">Reading a FeliCa Card (ICS-E008/10 : Sample 5 Format)</span>
      <button id="FeliCa" (click)="felica_card()">Execute</button>
    </div>
    <br><br>
    <div class="detect">
      <div id="detect-title" class="detect-title" style="display: inline;"></div><br>
      <div id="detect" class="detect-info" style="display: inline;"></div>
    </div>
    <br>
    <div class="communicate">
      <div id="communicate-title" class="communicate-title" style="display: inline;"></div><br>
      <div id="communicate" class="communicate-response" style="display: inline;"></div>
    </div>
  </div>-->