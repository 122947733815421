import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { user } from '../../../models/user';
import { DbService } from '../../../services/db.service';


@Component({
  selector: 'app-navi-qr',
  templateUrl: './navi-qr.component.html',
  styleUrls: ['./navi-qr.component.scss']
})
export class NaviQrComponent implements OnInit {
  qrdata: string = '';
  form!: FormGroup;
  checkbox!: FormGroup;
  mailtoControl = new FormControl(null, Validators.email);
  checkboxControl = new FormControl(null);
  showExpire: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<NaviQrComponent>,
    @Inject(MAT_DIALOG_DATA) public user: user,
    private dbService: DbService,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
  ) { }

ngOnInit(): void {
    this.form = this.fb.group({
      mailto: this.mailtoControl,
      cb: this.checkboxControl
    });
}

  onSubmit(): void {
    console.log(`mail address:${this.form.get('mailto')?.value}, showExpire:${this.showExpire}, formvalue:${this.form.get('cb')?.value}`);
    this.dbService.issueQr(this.form.get('mailto')?.value, !!(this.form.get('cb')?.value))
    .subscribe(result => {
      if(result){
        this.snackBar.open('メールを送信しました', '閉じる', {duration: 5000});
        this.dialogRef.close();
      }
      else{
        this.snackBar.open('送信できませんでした', '閉じる', {duration: 7000});
      }
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

}
