<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #drawer class="sidenav" fixedInViewport autoFocus="false "
        [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
        [mode]="(isHandset$ | async) ? 'over' : 'side'"
        [opened]="(isHandset$ | async) === false">
      <div fxLayout="column" fxFill>
        <mat-toolbar>DDLDoor</mat-toolbar>
        <mat-nav-list fxFlex>
          <a mat-list-item [routerLink]="['/home/dashboard']" routerLinkActive="active-list-item mat-elevation-z4" (click)="onClose()"><mat-icon class="list-icon">dashboard</mat-icon>ダッシュボード</a>
          <a mat-list-item [routerLink]="['/home/member']" routerLinkActive="active-list-item mat-elevation-z4" (click)="onClose()"><mat-icon class="list-icon">group</mat-icon>メンバー</a>
          <a mat-list-item [routerLink]="['/home/log']" routerLinkActive="active-list-item mat-elevation-z4" (click)="onClose()"><mat-icon class="list-icon">description</mat-icon>ログ</a>
          <a mat-list-item [routerLink]="['/home/nfc']" routerLinkActive="active-list-item mat-elevation-z4" (click)="onClose()"><mat-icon class="list-icon">nfc</mat-icon>カードID</a>
          <a mat-list-item [routerLink]="['/home/deviceList']" routerLinkActive="active-list-item mat-elevation-z4" (click)="onClose()"><mat-icon class="list-icon">device_hub</mat-icon>デバイス</a>
          <a mat-list-item [routerLink]="['/home/workHours']" routerLinkActive="active-list-item mat-elevation-z4" (click)="onClose()"><mat-icon class="list-icon">work</mat-icon>勤務時間</a>
          <a *ngIf="user.admin && !(isHandset$ | async)" mat-list-item [routerLink]="['/home/maintenance']" routerLinkActive="active-list-item mat-elevation-z4"><mat-icon class="list-icon">build</mat-icon>メンテナンス</a>
        </mat-nav-list>
        <span fxFlex></span>
        <div class="user" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="!(isHandset$ | async)">
          {{name}}
          <button mat-icon-button *ngIf="user.tutorial" type="button" [matMenuTriggerFor]="userMenu">
            <mat-icon class="more_vert" matBadge="!" matBadgeColor="warn" [matBadgeHidden]="!badge" matBadgeSize="small">more_vert</mat-icon>
          </button>
          <button mat-icon-button *ngIf="!user.tutorial" #menuTrigger="matMenuTrigger" type="button" [matMenuTriggerFor]="userMenu2" (menuClosed)="onMenuClose()">
            <mat-icon class="more_vert" matBadge="!" matBadgeColor="warn" [matBadgeHidden]="!badge" matBadgeSize="small">more_vert</mat-icon>
          </button>
          <mat-menu #userMenu="matMenu" class="user-profile" yPosition="above" xPosition="before">
            <button mat-menu-item (click)="onLogout()">
                <mat-icon>logout</mat-icon>
                ログアウト
            </button>
            <button mat-menu-item (click)="onResetPW()">
              <mat-icon>security</mat-icon>
                パスワード変更
            </button>
            <button mat-menu-item (click)="onSetMember()">
              <mat-icon>person_add</mat-icon>
                <span matBadge="!" matBadgeColor="warn" [matBadgeHidden]="!badge" matBadgeSize="small" matBadgeOverlap="false">メンバーリンク</span>
            </button>
            <button mat-menu-item (click)="onQr()">
              <mat-icon>mobile_screen_share</mat-icon>
                QRコード発行
            </button>
            <button mat-menu-item (click)="onHelp()">
              <mat-icon>info</mat-icon>
                ヘルプ
            </button>
          </mat-menu>
          <mat-menu #userMenu2="matMenu" class="user-profile" yPosition="above" xPosition="before" backdropClass="menuBack">
            <button mat-menu-item (click)="onLogout()">
                <mat-icon>logout</mat-icon>
                ログアウト
            </button>
            <button mat-menu-item (click)="onResetPW()">
              <mat-icon>security</mat-icon>
                パスワード変更
            </button>
            <button mat-menu-item (click)="onSetMember()">
              <mat-icon>person_add</mat-icon>
                <span matBadge="!" matBadgeColor="warn" [matBadgeHidden]="!badge" matBadgeSize="small" matBadgeOverlap="false">メンバーリンク</span>
            </button>
            <button mat-menu-item (click)="onQr()">
              <mat-icon>mobile_screen_share</mat-icon>
                ログイン用QR
            </button>
            <button mat-menu-item (click)="onHelp()">
              <mat-icon>info</mat-icon>
                ヘルプ
            </button>
          </mat-menu>
        </div>
      </div>
    </mat-sidenav>

    <mat-sidenav-content>
      <mat-toolbar *ngIf="isHandset$ | async" color="primary">
        <button
          type="button"
          aria-label="Toggle sidenav"
          mat-icon-button
          (click)="drawer.toggle()">
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
        <span class="navi-email" fxFlexOffset="auto">
          {{name}}
          <button
            *ngIf="user.tutorial"
            type="button"
            mat-icon-button
            [matMenuTriggerFor]="userMenu">
            <mat-icon matBadge="!" matBadgeColor="warn" [matBadgeHidden]="!badge" matBadgeSize="small">keyboard_arrow_down</mat-icon>
          </button>
          <button
            *ngIf="!user.tutorial"
            #menuTrigger="matMenuTrigger"
            (menuClosed)="onMenuClose()"
            type="button"
            mat-icon-button
            [matMenuTriggerFor]="userMenu2">
            <mat-icon matBadge="!" matBadgeColor="warn" [matBadgeHidden]="!badge" matBadgeSize="small">keyboard_arrow_down</mat-icon>
          </button>
          <mat-menu #userMenu="matMenu" class="user-profile" yPosition="above" xPosition="before">
            <button mat-menu-item (click)="onLogout()">
                <mat-icon>logout</mat-icon>
                ログアウト
            </button>
            <button mat-menu-item (click)="onResetPW()">
              <mat-icon>security</mat-icon>
                パスワード変更
            </button>
            <button mat-menu-item (click)="onSetMember()">
              <mat-icon>person_add</mat-icon>
                <span matBadge="!" matBadgeColor="warn" [matBadgeHidden]="!badge" matBadgeSize="small" matBadgeOverlap="false">メンバーリンク</span>
            </button>
            <button mat-menu-item (click)="onHelp()">
              <mat-icon>info</mat-icon>
                ヘルプ
            </button>
          </mat-menu>
          <mat-menu #userMenu2="matMenu" class="user-profile" yPosition="above" xPosition="before" backdropClass="menuBack">
            <button mat-menu-item (click)="onLogout()">
                <mat-icon>logout</mat-icon>
                ログアウト
            </button>
            <button mat-menu-item (click)="onResetPW()">
              <mat-icon>security</mat-icon>
                パスワード変更
            </button>
            <button mat-menu-item (click)="onSetMember()">
              <mat-icon>person_add</mat-icon>
                <span matBadge="!" matBadgeColor="warn" [matBadgeHidden]="!badge" matBadgeSize="small" matBadgeOverlap="false">メンバーリンク</span>
            </button>
            <button mat-menu-item (click)="onHelp()">
              <mat-icon>info</mat-icon>
                ヘルプ
            </button>
          </mat-menu>
        </span>
      </mat-toolbar>
      <ng-content></ng-content>
    </mat-sidenav-content>
  </mat-sidenav-container>
